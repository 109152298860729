<template>
  <el-dialog title="Settings" :visible.sync="showPopup" class="my-dialog-class">
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item label="Value">
            <el-input
              class="full-width"
              v-model="selectedObject.Value"
              placeholder="Value"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Close </el-button>
      <el-button type="primary" @click="save"> Save </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Settings_dialog",
  extends: BasePage,
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Vat: "",
      },
      Info: {},
      rules: {
        //   Nume: [ { required: true, message: "Campul este obligatoriu" } ]
        Vat: [{ required: true, message: "This field is required" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("settings/get_info_item_dialog", {
          id: id,
        });

        this.selectedObject = result.Item;
      }
    },
    async get_info() {
      var response = await this.post("settings/get_info_for_dialog");
    },
    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          await this.post("settings/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>